import React, { Component } from "react";
import {
	View,
	Text,
	StyleSheet,
	ScrollView,
	TouchableOpacity,
	Image,
} from "react-native";
import { APP_COLOURS } from "./APP_VARS";
import Font from "react-font";

class ImageLoop extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activeStep: 0,
		};

		this.scrollViewRef = React.createRef();
		this.steps = props.steps;

		this.interval = React.createRef();
	}

	componentDidMount() {
		this.startInterval();
		this.props.stepTitle(this.steps[0].title);
	}

	startInterval() {
		clearInterval(this.interval);
		clearTimeout(this.interval);
		this.interval = setInterval(() => {
			this.nextStep();
		}, 3000);
	}

	nextStep() {
		this.setState((prevState) => {
			const nextStep = (prevState.activeStep + 1) % this.steps.length;
			this.scrollToStep(nextStep);
			this.props.stepTitle(this.steps[nextStep].title);
			// console.log(nextStep);
			return { activeStep: nextStep };
		});
	}

	// prevStep() {
	// 	this.setState((prevState) => {
	// 		const prevStep =
	// 			(prevState.activeStep - 1 + this.steps.length) %
	// 			this.steps.length;
	// 		this.scrollToStep(prevStep);
	// 		this.props.stepTitle(this.steps[prevStep].step);
	// 		return { activeStep: prevStep };
	// 	});
	// }

	scrollToStep(step) {
		if (this.scrollViewRef.current) {
			this.scrollViewRef.current.scrollTo({
				x: step * 400, // Adjust the multiplier according to the item width
				y: 0,
				animated: true,
			});
		}
	}

	render() {
		return (
			<View style={styles.container}>
				<ScrollView
					horizontal
					ref={this.scrollViewRef}
					showsHorizontalScrollIndicator={false}>
					{this.steps.map((step, index) => (
						<View
							style={{
								maxWidth: 350,
								marginRight: 50,
								opacity:
									this.state.activeStep === index ? 1 : 0.2,
								backgroundColor: "#FFF",
								borderRadius: 15,
								padding: 20,
							}}
							key={index}>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
								}}>
								<Image
									source={{ uri: step.image }}
									style={{
										width: 300,
										height: 500,
										marginRight: 10,
										resizeMode: "contain",
									}}
								/>
							</View>
							<Text
								style={{
									fontSize: 20,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 10,
									flexDirection: "row",
								}}>
								<Font family='Montserrat' weight={500}>
									{step.title}
								</Font>
							</Text>

							<Text
								style={{
									fontSize: 20,
									color: APP_COLOURS.TEXTCOLOR,
									marginBottom: 10,
									// textAlign: "justify",
									fontFamily: "Montserrat",
									fontWeight: "300",
								}}>
								{step.description}
							</Text>
						</View>
					))}
				</ScrollView>

				{/* <View style={styles.buttonsContainer}>
					<TouchableOpacity
						onPress={() => this.prevStep()}
						style={styles.button}>
						<Text style={styles.buttonText}>Back</Text>
					</TouchableOpacity>
					<TouchableOpacity
						onPress={() => this.nextStep()}
						style={styles.button}>
						<Text style={styles.buttonText}>Forward</Text>
					</TouchableOpacity>
				</View> */}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {},
	buttonsContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginTop: 20,
	},
	button: {
		backgroundColor: APP_COLOURS.TEXTCOLOR,
		paddingHorizontal: 20,
		paddingVertical: 10,
		borderRadius: 5,
		marginRight: 20,
	},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
});

export default ImageLoop;
