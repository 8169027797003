import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/19724-support-help-customer-service.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page5 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = this.props.screenHeight;
		let w = this.props.screenWidth > 1000 ? 1000 : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					// paddingBottom: "10%",
					// marginBottom: "10%",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					{/* <View
						style={{
							width: w,
							justifyContent: "center",
							alignItems: "center",
							position: "absolute",
							zIndex: 0,
							top: h * 0.35,
						}}>
						<InView
							as='div'
							delay={100}
							trackVisibility={true}
							onChange={(bool, entry) => {
								clearTimeout(this._timeout);
								this._timeout = setTimeout(() => {
									// console.log(bool);
									this.setState({
										inView: bool,
										showObjects: bool,
										showPhone: bool,
									});
								}, 100);
							}}>
							<div style={{}}>
								<Image
									source={logo_tetrice}
									style={{
										width: h * 0.4 * (501 / 463),
										height: h * 0.4,
										resizeMode: "contain",

										opacity: 0,
									}}
								/>
							</div>
						</InView>
					</View> */}

					<View
						style={{
							width: w,
							// height: h,
							height: "auto",
							padding: 20,
							// paddingTop: "10%",
							zIndex: 1,
						}}>
						<Fade>
							<div
							// className={
							// 	this.state.inView ? `reveal` : `fadeOut`
							// }
							>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h * 0.2,
										width: w,
										// marginBottom: "10%",
									}}>
									<View
										style={{
											flex: 1,
											// padding: 20,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 50,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													Understanding your Customers
												</Font>
											</Text>
											<Text
												style={{
													fontSize: 22,

													marginBottom: 5,
													flexDirection: "row",
													opacity: 0.4,
													color: "#000",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													Discovering the desires of
													the discerning shopper.
												</Font>
											</Text>
										</View>
									</View>
									<View
										style={{
											flex: 1,
											padding: 20,
										}}>
										<Lottie
											options={defaultOptions}
											height={h * 0.4}
											width={h * 0.45}
										/>
									</View>
								</View>

								{/* <View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h / 2,
										width: w,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												To better engage with our
												customers, we have segmented our
												target audience based on
												demographics, interests, and
												behaviors. This segmentation
												allows us to tailor our
												messaging and tactics to
												resonate with each group,
												ensuring our communications are
												relevant and impactful. Our
												customer segments include:
											</Font>
										</Text>

										{[
											{
												title: "Aspirational Achievers:",
												desc: "Young, upwardly mobile professionals seeking to elevate their personal brand and indulge in luxury experiences as a symbol of their success.",
											},
											{
												title: "Established Elites:",
												desc: "Established Elites: High net worth individuals with a penchant for classic, timeless luxury, who appreciate the heritage and craftsmanship behind our products.",
											},
											{
												title: "Trendsetters:",
												desc: "Fashion-forward individuals who are always on the lookout for the latest trends and seek unique, statement pieces to express their personal style.",
											},
											{
												title: "Conscious Connoisseurs:",
												desc: "Customers who value sustainability and social responsibility, seeking luxury brands that align with their ethical beliefs and promote transparency in their practices.",
											},
										].map((d, i) => (
											<Text
												style={{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 15,
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{d.title}
												</Font>
												<Font
													family='Montserrat'
													weight={300}>
													{d.desc}
												</Font>
											</Text>
										))}

										<Text
											style={{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 5,
												flexDirection: "row",
												marginTop: 25,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												By understanding the unique
												needs and preferences of each
												customer segment, we can create
												targeted, data-driven strategies
												that foster genuine connections
												with our audience, driving
												engagement and brand loyalty.
											</Font>
										</Text>
									</View>
								</View> */}
							</div>
						</Fade>
					</View>

					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
