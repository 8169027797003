import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";

import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";
import ScaleView from "./ScaleView";

export default class IntroPage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		// const defaultOptions = {
		// 	loop: true,
		// 	autoplay: true,
		// 	animationData: animationData,
		// 	rendererSettings: {
		// 		preserveAspectRatio: "xMidYMid slice",
		// 	},
		// };

		let h = this.props.screenHeight;
		let w = this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 265 / 345;

		let bias = h > w ? w : h;

		return (
			<View
				style={{
					// flex: 1,
					width: this.props.screenWidth,
					// height: this.props.screenHeight,
					height: "auto",
					// maxWidth: 700,
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					backgroundColor: "#EDF1F4",
					// overflow: "hidden",
					// justifyContent: "center",
					// alignItems: "center",
					// paddingTop: "30%",
					// paddingBottom: "10%",

					padding: "10%",

					justifyContent: "center",
					alignItems: "center",
				}}>
				<View
					style={{
						flex: 1,
						flexDirection: "row",
						maxWidth: 1000,
						// justifyContent: "center",
						// alignItems: "center",
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								flex: 1,
								// padding: 40,
								flexDirection: "row",
								// justifyContent: "flex-end",
								// alignItems: "flex-end",
								// maxHeight: h * 0.5,
							}}>
							<View
								style={{
									flex: 1,
									// maxWidth: w * 0.5,
									// paddingLeft: 30,
								}}>
								<Fade>
									<Text
										style={{
											fontSize: 48,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={800}>
											Elevate & Engage:
										</Font>
									</Text>

									{/* <Text
										style={{
											fontSize: 29,
											color: APP_COLOURS.TEXTCOLOR,
										}}>
										<Font family='Poppins' weight={600}>
											Audit smarter, not harder
										</Font>
									</Text> */}
									<Text
										style={{
											fontSize: 20,
											color: "#000",
											opacity: 0.6,
										}}>
										<Font family='Poppins' weight={400}>
											Transforming the way we engage,
											inspire, and connect with our
											audience.
										</Font>
									</Text>
								</Fade>
							</View>
							<View
								style={{
									flex: 1,
									// backgroundColor: "#FFF",
									justifyContent: "flex-end",
									alignItems: "flex-end",
									marginTop: 90,
								}}>
								<Image
									source={require("./assets/photo-1589187775328-882e91b3db4f.jpeg")}
									style={{
										width: bias * 0.5,
										height: bias * 0.5 * ar,
										resizeMode: "contain",

										// position: "absolute",
										bottom: 0,
										right: 0,
									}}
								/>
							</View>
						</View>
					</View>
				</View>
				<View
					style={{
						maxWidth: 1000,
						width: "100%",
					}}>
					{[
						{
							title: "Offline Data Caching",
							desc: "This feature allows users to collect data even without an internet connection, ensuring that no data is lost even in remote locations.",
						},
						{
							title: "Customizable Surveys and Questionnaires",
							desc: "TRAQ allows users to create and customize surveys and questionnaires to fit their specific needs and goals.",
						},
						{
							title: "Dashboard",
							desc: "The TRAQ dashboard provides a clear and concise overview of all collected data, allowing users to easily analyze and visualize results.",
						},
						{
							title: "Real-Time Syncing",
							desc: "Once an internet connection is re-established, data collected offline will automatically sync with the cloud, ensuring that all data is up-to-date.",
						},
						{
							title: "Customer Insights",
							desc: "TRAQ's data analysis tools help users gain valuable insights into their customers' needs and preferences, allowing them to make informed business decisions.",
						},
						{
							title: "Increased Efficiency",
							desc: "With its offline data collection capabilities and real-time syncing, TRAQ helps users save time and increase efficiency in their auditing and surveying processes.",
						},
						{
							title: "User-Friendly Interface",
							desc: "The app's user-friendly interface makes it easy for users to collect and analyze data, without any technical expertise.",
						},
					].map((d, i) => (
						<Text
							style={{
								fontSize: 19,
								color: APP_COLOURS.TEXTCOLOR,
								marginBottom: 15,
								flexDirection: "row",
								marginBottom: 5,
							}}>
							<Font family='Montserrat' weight={500}>
								{d.title}
							</Font>
							<Font family='Montserrat' weight={300}>
								{d.desc}
							</Font>
						</Text>
					))}
				</View>
			</View>
		);
	}
}
