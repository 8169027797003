import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
import logo_tetrice from "./assets/traqbifull.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import bg1 from "./assets/devices.png";
import { APP_COLOURS } from "./APP_VARS";

import Lottie from "react-lottie";
import * as animationData2 from "./assets/server_room_2.json";

import "./resizeScale.scss";
import "./hoverEffect.scss";

export default class LandingPage extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({
				inView: true,
				showObjects: true,
				showPhone: true,
			});
		}, 500);
	}

	render() {
		let h = this.props.screenHeight * 0.55;
		let w = this.props.screenWidth;
		let contain =
			this.props.screenWidth > this.props.screenHeight6
				? this.props.screenHeight * 0.6
				: this.props.screenWidth * 0.6;

		let maxContain = contain > 600 ? 600 : contain;

		let ar = 1246.11 / 722.44;

		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let paddingLeft = this.props.paddingLeft;

		return (
			<View
				style={{
					// flex: 1,
					width: w,
					// height: 500,
					// backgroundColor: "#171A20",
					backgroundColor: "#14141A",
					padding: 40,
					paddingTop: 0,
					// paddingLeft: "15%",
					justifyContent: "center",
					alignItems: "center",
					paddingRight: 0,
					overflow: "hidden",

					// top: -70,
					paddingLeft: paddingLeft,
				}}>
				{/* <View
					style={{
						position: "absolute",
						right: 0,
						height: "100%",
						width: w * 0.5,
					}}>
					<Image
						source={require("./assets/dots.png")}
						style={{
							width: w * 0.35,
							height: w * 0.35,
							resizeMode: "contain",
						}}
					/>
				</View> */}
				<View
					style={{
						// flex: 1,
						flexDirection: "row",
						// flexWrap: "wrap",
						justifyContent: "space-between",
						width: "100%",
					}}>
					<View
						style={{
							// flex: 1,
							justifyContent: "center",
							minWidth: 320,
							maxWidth: 500,
							// alignItems: "center",
						}}>
						<View
							style={{
								width: "100%",
								justifyContent: "flex-start",
							}}>
							<Image
								source={logo_tetrice}
								style={{
									width: 120 * (472 / 320),
									height: 120,
									resizeMode: "contain",
								}}
							/>
						</View>
						<View
							style={{
								marginVertical: 25,
							}}>
							<Fade cascade left>
								<Text
									style={{
										fontSize: 45,
										color: "#FFF",
									}}>
									<Font family='Poppins' weight={800}>
										the ultimate data collection tool.
									</Font>
								</Text>
								<Text
									style={{
										fontSize: 26,
										color: "#FFF",
									}}>
									<Font family='Poppins' weight={400}>
										Highly performant, scalable data
										collection & processing tools.
									</Font>
								</Text>
							</Fade>
						</View>
						<View
							style={
								{
									// flex: 1,
								}
							}>
							<Image
								source={require("./assets/services.png")}
								style={{
									width: 320,
									height: 150,
									resizeMode: "contain",
									// position: "absolute",
									// left: -10,
								}}
							/>
						</View>
					</View>

					<View
						style={{
							flex: 1,
						}}
					/>

					<View
						style={{
							flex: 4,
							// minWidth: 320,
							// maxWidth: 800,
							// backgroundColor: "red",
							alignItems: "flex-start",
							justifyContent: "flex-start",
							paddingBottom: "10%",
						}}>
						<View
							style={{
								flex: 1,
								flexDirection: "row",
								alignItems: "center",
								paddingTop: "10%",
								paddingBottom: "20%",
							}}>
							<View
								style={{
									flex: 1,
									alignItems: "flex-end",
								}}>
								<View
									style={{
										position: "absolute",
										top: 300,
										left: -120,
									}}>
									<Lottie
										options={defaultOptions}
										height={500}
										width={600}
									/>
								</View>
								<Image
									source={bg1}
									style={{
										width: 555 * ar,
										height: 555,
										objectFit: "contain",
										bottom: 20,
									}}
								/>
							</View>
						</View>
					</View>
				</View>

				{/* <View
					style={{
						position: "absolute",
						right: 0,
						height: "100%",
						width: w * 0.5,
					}}>
					<Image
						source={bg1}
						style={{
							width: maxContain * ar,
							height: maxContain,
							resizeMode: "contain",
							position: "absolute",
							right: -10,
							top: -90,
						}}
					/>
				</View> */}

				{/* <View
					style={{
						// padding: 30,
						width: "100%",
						flexDirection: "row",
						justifyContent: "flex-end",
						// paddingRight: 30,
						// paddingLeft: 30,
						paddingBottom: 10,
						alignItems: "center",
						position: "absolute",
						right: 20,
						bottom: 20,
					}}>
					<Image
						source={require("./assets/infod.png")}
						style={{
							width: 240,
							height: 120,
							resizeMode: "contain",
							// position: "absolute",
							// left: -10,
						}}
					/>
				</View> */}
			</View>
		);
	}
}
