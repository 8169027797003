import React, { Component, useRef } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Font from "react-font";
import Fade from "react-reveal/Fade";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/41111-man-filling-a-survey-with-a-woman-watching-at-5-star-feedback-dialog.json";
import * as animationData3 from "./assets/92323-dot-pattern-background.json";
import * as animationData4 from "./assets/CirclePop.json";

import "./resizeScale.scss";
import "./hoverEffect.scss";

import moment from "moment";
import ImageLoop from "./ImageLoop";
import LandingPage from "./LandingPage";

export default class ProposalPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inView: false,
			screenWidth: window.innerWidth,
			screenheight: window.innerHeight,
			clientLogo: "",

			pages: [
				{
					body: [
						{
							type: "h1",
							text: "Comprehensive Online Survey Platform for B2B Market Research",
							subtitle: ``,
						},
						{
							type: "lottie",
							data: animationData2,
						},
					],
				},
				{
					body: [
						{
							type: "h2",
							text: "Project scope",
						},
						// {
						// 	type: "img",
						// 	data: require("./assets/screenbshot1.png"),
						// },
					],
				},

				{
					body: [
						{
							type: "p",
							text: "Setup and implement a comprehensive online survey platform to collect insights from four B2B market segments, including OEMs, short-term insurers, dealers, and ISPs. The platform will provide a means to host, track, and collect survey data. Once all survey data has been collected, all data will be extracted and exported.",
						},
					],
				},
				{
					body: [
						{
							type: "li",
							text: "Survey Design:",
							subtitle: ``,
							items: [
								{
									subtitle: `a. Development of four different online surveys, each targeted at a specific market segments: OEM (n=40), short-term insurers (n=60), dealers (n=390), and ISPs (n=390).`,
								},
								{
									subtitle: `b. Each survey will consist of 20 structured questions, with a total estimated completion time of 30 minutes per survey.`,
								},
								{
									subtitle: `c. Incorporate question routing based on respondent's answers to optimize the survey-taking experience and ensure relevant data collection.`,
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "li",
							text: "Survey Distribution:",
							subtitle: ``,
							items: [
								{
									subtitle: `a. Unique links will be provided for each target group for self-completion online.`,
								},
								{
									subtitle: `b. Provide independent survey links to researchers, allowing them to conduct interviews based on the online survey questions.`,
								},
								// {
								// 	subtitle: `c. Set up a reminder system to send follow-up emails to non-respondents after a specified period of time.`,
								// },
							],
						},
					],
				},
				{
					body: [
						{
							type: "li",
							text: "Survey Administration and Tracking:",
							subtitle: ``,
							items: [
								{
									subtitle: `a. IP tracking and unique identifiers for each respondent in order to see response rates. (subject to network & IP tracking avaliability)`,
								},
								{
									subtitle: `b. Enable researchers to conduct interviews using the online survey platform with their unique survey links.`,
								},
								{
									subtitle: `c. Keep the survey open for a predetermined period of time to maximize response rates.`,
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "li",
							text: "Data Collection and Output:",
							subtitle: ``,
							items: [
								{
									subtitle: `a. Collect data in a structured format that can be exported as an Excel file, CSV, or other preferred formats.`,
								},
								{
									subtitle: `b. Store and manage collected data securely within the survey platform.`,
								},
							],
						},
					],
				},

				// {
				// 	body: [
				// 		{
				// 			type: "li",
				// 			text: "Select a well-established online survey platform:",
				// 			subtitle: ``,
				// 			items: [
				// 				{
				// 					subtitle: `Complex routing, IP tracking, and unique respondent identifiers.`,
				// 				},
				// 			],
				// 		},
				// 	],
				// },
				{
					body: [
						{
							type: "li",
							text: "Develop and test the surveys:",
							subtitle: ``,
							items: [
								{
									subtitle: `After setup & development, Ensuring all routing and tracking features are functioning as intended.`,
								},
							],
						},
					],
				},
				// {
				// 	body: [
				// 		{
				// 			type: "li",
				// 			text: "Set up the distribution and reminders:",
				// 			subtitle: ``,
				// 			items: [
				// 				{
				// 					subtitle: `Within the chosen platform, including configuring email templates and scheduling the survey invitation and reminder emails.`,
				// 				},
				// 			],
				// 		},
				// 	],
				// },
				{
					body: [
						{
							type: "li",
							text: "Provide training and support:",
							subtitle: ``,
							items: [
								{
									subtitle: `To researchers who will be conducting interviews based on the online survey questions, ensuring they are familiar with the platform's features and capabilities.`,
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "li",
							text: "Monitor survey responses and completion rates:",
							subtitle: ``,
							items: [
								{
									subtitle: `Throughout the survey administration period, adjusting strategies as needed to optimize response rates.`,
								},
							],
						},
					],
				},
				{
					body: [
						{
							type: "li",
							text: "Export collected data:",
							subtitle: ``,
							items: [
								{
									subtitle: `At the end of the survey administration period, export the collected data in the desired format for further analysis and reporting.`,
								},
							],
						},
					],
				},

				// {
				// 	body: [
				// 		{
				// 			type: "slide",
				// 			items: [
				// 				{
				// 					image: require("./assets/0db9e425-520f-45e4-9327-a4972472f786.JPG"),
				// 					title: "",
				// 					description: "Screenshot",
				// 				},
				// 				{
				// 					image: require("./assets/b9b964da-7435-4274-8125-4b778e063f21.JPG"),
				// 					title: "",
				// 					description: "Screenshot",
				// 				},
				// 				{
				// 					image: require("./assets/a27c9b77-95b8-448f-b837-2d6216b5bae4.JPG"),
				// 					title: "",
				// 					description: "Screenshot",
				// 				},
				// 				{
				// 					image: require("./assets/78258cf9-0f02-4fb4-9267-2f5812622f89.JPG"),
				// 					title: "",
				// 					description: "Screenshot",
				// 				},
				// 			],
				// 		},
				// 	],
				// },
			],

			costEstimate: {
				clientDetails: "Safala Strategic solutions",
				title: "Online Survey platform for B2B Market Research",
				description: `To accurately determine the cost of the application, we need to create a flow diagram and wireframe to design the platform. This will enable us to determine the development and time required to complete the project, as well as the scale and detail of backend services. We must take into account important factors such as the amount of data to be streamed, the number of users the app will have, and the data being stored and distributed.

            Some aspects of the application involve development-only costs, while others require minimal development yet carry a monthly running cost. In addition, we have drafted a mock-up of the app to provide a visual representation of the proposed user interface. Of course, the user interface will be designed to reflect the brand's product colors.`,

				lines: [
					{
						title: "TRAQ Audit - Survey setup costs",

						price: "R 138,000",
						priceDesc: "once off",
						description: [
							`Survey setup cost x 4`,
							`Skip logic x 4`,
							`Domain provisioning x 4`,
							`Custom URL Tracking`,
							`Database & Encryption`,
							`Research assistant profile setup`,
						],
					},
					{
						title: "Admin dashboard",
						description: [`Live reporting`, `Data extracts`],
						price: "R 375",
						priceDesc: "/user/month",
					},
					{
						title: "Cloud storage",
						description: [`Cloud Services`, `Data storage`],
						price: "R 485",
						priceDesc: "/GB/month",
					},
				],
				additional: `Monthly thresholds are applicable and service costs are based on a mimimum level of useage. Service costs include:

- TRAQ SaaS platform (10,000 interactions)
- Cloud Storage: 1GB SSD storage size
- Cloud backups & snapshots
- A system-level backup is taken once a day, and each backup is retained for 4 weeks.
- 24/7 tech-support

Communications thresholds:
- SMS's (not included)
- Email's (not included)`,
				terms: ``,

				expiry: moment("01/07/2023", "DD/MM/YYYY").format(
					"DD MMM YYYY",
				),
			},
		};

		this.scrollRef = React.createRef();
		this.footerRef = React.createRef();
	}

	pages = (d) => {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = window.innerHeight;
		let w = window.innerWidth * 0.7;

		let imgWid = w / d.body.length > 400 ? 400 : w / d.body.length;

		return (
			<div
				style={{
					width: w,
					height: "auto",
					// justifyContent: "center",
					// alignItems: "center",
					zIndex: 5,
				}}>
				<View
					style={{
						flex: 1,
						// justifyContent: "center",
						// alignItems: "center",
					}}>
					<View
						style={{
							width: w,
							// height: h,
							height: "auto",

							zIndex: 1,
							// maxWidth: 1200,
						}}>
						<Fade>
							<View
								style={{
									flexDirection: "row",
									width: "100%",
									justifyContent: "space-between",
									marginBottom: 10,
									alignItems: "center",
								}}>
								{d.body.map((dd) =>
									dd.type === "img" ? (
										<Image
											source={dd.data}
											style={{
												width: imgWid,
												height: imgWid,
												resizeMode: "contain",
											}}
										/>
									) : dd.type === "h1" ? (
										<View
											style={{
												// flex: 1,
												width: "100%",
												marginTop: 20,
												marginBottom: 10,

												minWidth: 400,
												maxWidth: 500,
											}}>
											<Text
												style={{
													fontSize: 40,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													{dd.text}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: 22,
													marginBottom: 5,
													flexDirection: "row",
													opacity: 0.4,
													color: "#000",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{dd.subtitle}
												</Font>
											</Text>
										</View>
									) : dd.type === "h2" ? (
										<View
											style={{
												flex: 1,
												marginTop: 20,
												marginBottom: 10,
												maxWidth: 800,
												minWidth: w * 0.5,
											}}>
											<Text
												style={{
													fontSize: 23,
													color: APP_COLOURS.TEXTCOLOR,

													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{dd.text}
												</Font>
											</Text>
										</View>
									) : dd.type === "p" ? (
										<Text
											style={{
												flex: 1,
												fontSize: 16,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 5,
												fontFamily: "Montserrat",
												textAlign: "justify",
												fontWeight: "300",
												maxWidth: 800,
											}}>
											{/* <Font
													family='Montserrat'
													weight={300}> */}
											{dd.text}
											{/* </Font> */}
										</Text>
									) : dd.type === "li" ? (
										<View
											style={{
												flex: 1,
												width: "100%",
												maxWidth: 800,
											}}>
											<Text
												style={{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{dd.text}
												</Font>
											</Text>
											<Text
												style={{
													fontSize: 16,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 15,
													flexDirection: "row",
												}}>
												<Font
													family='Montserrat'
													weight={300}>
													{dd.subtitle}
												</Font>
											</Text>
											{dd.items.map((ddd) => (
												<View style={{}}>
													{/* <Text
														style={{
															fontSize: 16,
															color: APP_COLOURS.TEXTCOLOR,
															marginBottom: 5,
															flexDirection:
																"row",
															// paddingLeft: 15,
														}}>
														<Font
															family='Montserrat'
															weight={300}>
															- {ddd.text}
														</Font>
													</Text> */}
													<Text
														style={{
															fontSize: 16,
															color: APP_COLOURS.TEXTCOLOR,
															marginBottom: 15,
															flexDirection:
																"row",
															paddingLeft: 15,
														}}>
														<Font
															family='Montserrat'
															weight={300}>
															{ddd.subtitle}
														</Font>
													</Text>
												</View>
											))}
										</View>
									) : dd.type === "slide" ? (
										<View
											style={{
												flex: 1,
											}}>
											<ImageLoop
												steps={dd.items}
												stepTitle={(title) =>
													this.setState({
														stepTitle: title,
													})
												}
											/>
										</View>
									) : dd.type === "lottie" ? (
										<View
											style={{
												flex: 1,
											}}>
											<Lottie
												options={defaultOptions}
												height={450}
												width={550}
											/>
										</View>
									) : null,
								)}
							</View>
						</Fade>
					</View>
				</View>
			</div>
		);
	};

	handleScroll = (event) => {
		const { contentOffset, layoutMeasurement, contentSize } =
			event.nativeEvent;
		const position = contentOffset.y;
		const height = layoutMeasurement.height;
		const totalHeight = contentSize.height;
		const newPosition = position / (totalHeight - height);

		const viewHeight = (newPosition + 1) * height * 0.2;

		const perc = newPosition * 100;

		if (this.scrollRef) {
			this.scrollRef.current.style.height = `${perc}%`;
			if (perc > 99) {
				this.footerRef.current.style.display = `flex`;
			} else {
				this.footerRef.current.style.display = `none`;
			}
			// console.log(newPosition);
		}
	};

	render() {
		const { scrollPosition, viewHeight } = this.state;
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		const defaultOptions2 = {
			loop: true,
			autoplay: true,
			animationData: animationData3,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		const defaultOptions3 = {
			loop: true,
			autoplay: true,
			animationData: animationData4,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = window.innerHeight;
		let w = window.innerWidth;

		let ce = this.state.costEstimate;

		let paddingLeft = w > 1800 ? "15%" : w > 1500 ? "10%" : "5%";

		return (
			<View
				style={{
					width: w,
					height: h,

					backgroundColor: "#ECEFF2",
					overflow: "hidden",
				}}>
				<View
					ref={this.scrollRef}
					style={{
						position: "absolute",
						right: 25,
						marginRight: paddingLeft,
						bottom: 0,
						// height: h * 0.2,
						height: 0,
						width: 1,
						borderRightWidth: 2,
						borderStyle: "dashed",
						borderRightColor: "#B3DAE8",
					}}>
					<View
						style={{
							width: 65,
							height: 65,
							resizeMode: "contain",
							position: "absolute",
							alignItems: "center",
							justifyContent: "center",
							// right: "10%",
							right: -32,
							top: 0,
							// bottom: this.scrollRef,
							backgroundColor: "#ECEFF2",
							zIndex: 10,
						}}>
						<Image
							source={require("./assets/traq_q1.png")}
							style={{
								width: 40,
								height: 40,
								resizeMode: "contain",
								position: "absolute",
								zIndex: 10,
							}}
						/>
						<View
							style={{
								opacity: 0.3,
							}}>
							<Lottie
								options={defaultOptions3}
								height={65}
								width={65}
							/>
						</View>
					</View>

					<View
						style={{
							flex: 1,
							// transform: [{ rotate: "180deg" }],
							// transform: [{ scaleY: -1 }],
						}}>
						<Lottie
							options={defaultOptions2}
							height={300}
							width={150}
						/>
					</View>
					<View
						style={{
							flex: 1,
						}}>
						<Lottie
							options={defaultOptions2}
							height={300}
							width={150}
						/>
					</View>
					<View
						style={{
							flex: 1,
						}}>
						<Lottie
							options={defaultOptions2}
							height={300}
							width={150}
						/>
					</View>
				</View>

				<ScrollView
					overScrollMode='never'
					onScroll={this.handleScroll}
					scrollEventThrottle={16}
					showsVerticalScrollIndicator={false}>
					<LandingPage {...this.state} paddingLeft={paddingLeft} />

					<View
						style={{
							// padding: 20,
							// paddingTop: "10%",
							// overflow: "hidden",
							// width: "100%",
							paddingHorizontal: paddingLeft,
						}}>
						<View
							style={{
								width: 250,
								height: 120,
								borderRadius: 10,
								overflow: "hidden",
								backgroundColor: "#FFF",
								justifyContent: "center",
								alignItems: "center",
								top: -50,
							}}>
							<Image
								source={require("./assets/safala.png")}
								style={{
									width: 250,
									height: 120,
									resizeMode: "cover",
								}}
							/>
						</View>
						{this.state.pages.map((d) => this.pages(d))}
						<div
							style={{
								marginTop: 100,
								minHeight: h,
							}}>
							<View
								style={{
									position: "absolute",
									top: 0,
									right: 0,
									zIndex: 1,
								}}>
								<Image
									source={require("./assets/dots.png")}
									style={{
										width: 500,
										height: 500,
										resizeMode: "contain",
									}}
								/>
							</View>

							<View
								style={{
									flex: 1,
									width: "100%",
									// justifyContent: "center",
									// alignItems: "center",
									paddingBottom: 250,
								}}>
								<View
									style={{
										// width: w,

										// height: h,
										height: "auto",
										maxWidth: 1200,

										flex: 1,
										zIndex: 1,
									}}>
									<View
										style={{
											// flex: 1,
											flexDirection: "row",
											// height: h * 0.2,
											// width: w,
											// marginBottom: "40%",
										}}>
										<View
											style={{
												flex: 1,
												// padding: 20,
												flexDirection: "row",
												alignItems: "center",
												justifyContent: "center",
											}}>
											<View
												style={{
													flex: 1,
												}}>
												<Text
													style={{
														fontSize: 30,
														color: APP_COLOURS.TEXTCOLOR,
														marginBottom: 30,
													}}>
													<Font
														family='Poppins'
														weight={300}>
														Cost estimate
													</Font>
												</Text>

												<Text
													style={{
														fontSize: 30,
														color: APP_COLOURS.TEXTCOLOR,
													}}>
													<Font
														family='Poppins'
														weight={500}>
														{ce.clientDetails}
													</Font>
												</Text>
												<Text
													style={{
														fontSize: 35,
														color: APP_COLOURS.TEXTCOLOR,
													}}>
													<Font
														family='Poppins'
														weight={700}>
														{ce.title}
													</Font>
												</Text>
											</View>
										</View>
									</View>

									<View
										style={
											{
												// width: w,
											}
										}>
										{ce.lines.map((dd) => (
											<View
												style={{
													marginTop: 20,
													marginBottom: 20,
													paddingBottom: 10,
													borderBottomWidth: 0.5,
													borderColor:
														APP_COLOURS.TEXTCOLOR,
													marginRight: 50,
												}}>
												<View
													style={{
														flexDirection: "row",
														justifyContent:
															"space-between",
													}}>
													<Text
														style={{
															fontSize: 19,
															color: APP_COLOURS.TEXTCOLOR,
														}}>
														<Font
															family='Montserrat'
															weight={700}>
															{dd.title}
														</Font>
													</Text>
													<View
														style={{
															alignItems:
																"flex-end",
															justifyContent:
																"flex-end",
														}}>
														<Text
															style={{
																fontSize: 16,
																color: APP_COLOURS.TEXTCOLOR,

																flexDirection:
																	"row",
															}}>
															<Font
																family='Montserrat'
																weight={700}>
																{dd.price}
															</Font>
														</Text>
														<Text
															style={{
																fontSize: 13,
																color: APP_COLOURS.TEXTCOLOR,
																flexDirection:
																	"row",
															}}>
															<Font
																family='Montserrat'
																weight={300}>
																{dd.priceDesc}
															</Font>
														</Text>
													</View>
												</View>
												{dd.description.map((des) => (
													<Text
														style={{
															fontSize: 15,
															color: APP_COLOURS.TEXTCOLOR,
														}}>
														<Font
															family='Montserrat'
															weight={300}>
															- {des},
														</Font>
													</Text>
												))}
											</View>
										))}
									</View>

									{/* <View
									style={{
										width: "100%",
										borderWidth: 0.5,
										borderColor: APP_COLOURS.TEXTCOLOR,
									}}
								/> */}

									<Text
										style={{
											fontSize: 15,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 2,
											flexDirection: "row",
											marginTop: 2,
										}}>
										<Font family='Montserrat' weight={300}>
											{ce.additional}
										</Font>
									</Text>

									<Text
										style={{
											fontSize: 15,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 2,
											flexDirection: "row",
											marginTop: 2,
										}}>
										<Font family='Montserrat' weight={300}>
											{ce.terms}
										</Font>
									</Text>

									<Text
										style={{
											fontSize: 13,
											color: moment().isAfter(
												moment(ce.expiry),
											)
												? APP_COLOURS.RED
												: APP_COLOURS.TEXTCOLOR,
											marginBottom: 2,
											flexDirection: "row",
											marginTop: 50,
										}}>
										<Font family='Montserrat' weight={300}>
											* This quotation is valid until{" "}
											{ce.expiry}
											{moment().isAfter(moment(ce.expiry))
												? ", and has expired"
												: `, expires ${moment(
														ce.expiry,
												  ).fromNow()}`}
										</Font>
									</Text>

									<Text
										style={{
											fontSize: 13,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 2,
											flexDirection: "row",
										}}>
										<Font family='Montserrat' weight={300}>
											* All quoted amounts are monthly
											recurring and exclusive of VAT
											unless stated otherwise.
										</Font>
									</Text>
								</View>
							</View>
						</div>
					</View>
				</ScrollView>
				<View
					ref={this.footerRef}
					style={{
						width: "100%",
						backgroundColor: "#1C2B46",
						height: 85,
						position: "absolute",
						bottom: 0,
						left: 0,
						justifyContent: "center",
						alignItems: "center",
						display: "none",
					}}>
					<Fade>
						<View
							style={{
								width: w,

								left: 0,
								paddingLeft: paddingLeft,
							}}>
							<View
								style={{
									position: "absolute",
									bottom: 60,
									right: 0,
								}}>
								<Image
									source={require("./assets/agreeMent.png")}
									style={{
										width: 250 * (368.94 / 255.42),
										height: 250,
										resizeMode: "contain",
									}}
								/>
							</View>
							<View
								style={{
									width: "100%",
									flexDirection: "row",
									// maxWidth: 1200,
									justifyContent: "space-between",
								}}>
								<Image
									source={require("./assets/services.png")}
									style={{
										width: 300,
										height: 35,
										resizeMode: "contain",
									}}
								/>
								<Image
									source={require("./assets/infod.png")}
									style={{
										width: 220,
										height: 35,
										resizeMode: "contain",
									}}
								/>
							</View>
						</View>
					</Fade>
				</View>
			</View>
		);
	}
}
