import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";

import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inView: false,
		};
	}

	render() {
		// const defaultOptions = {
		// 	loop: true,
		// 	autoplay: true,
		// 	animationData: animationData,
		// 	rendererSettings: {
		// 		preserveAspectRatio: "xMidYMid slice",
		// 	},
		// };

		let h = this.props.screenHeight;
		let w = this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					backgroundColor: "#EDF1F4",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: 100,
				}}>
				<View
					style={{
						width: "100%",

						justifyContent: "center",
						alignItems: "center",
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							position: "absolute",
							zIndex: 0,
							top: h * 0.35,
						}}>
						<InView
							as='div'
							delay={100}
							trackVisibility={true}
							onChange={(bool, entry) => {
								clearTimeout(this._timeout);
								this._timeout = setTimeout(() => {
									// console.log(bool);
									this.setState({
										inView: bool,
										showObjects: bool,
										showPhone: bool,
									});
								}, 100);
							}}>
							<div style={{}}>
								<Image
									source={logo_tetrice}
									style={{
										width: h * 0.4 * (501 / 463),
										height: h * 0.4,
										resizeMode: "contain",

										opacity: 0,
									}}
								/>
							</div>
						</InView>
					</View>

					<View
						style={{
							// width: "100%",
							// padding: "14%",
							// paddingLeft: "23%",
							// paddingRight: "23%",
							width: "100%",
							maxWidth: 1200,
						}}>
						<div
							className={
								this.state.inView ? `reveal` : `fadeOut`
							}>
							<View
								style={{
									// flex: 1,
									flexDirection: "row",
									// height: h / 2,
									// width: "100%",
								}}>
								<View
									style={{
										// flex: 1,
										width: "100%",
									}}>
									<Text
										style={{
											fontSize: 40,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 50,
											marginTop: 20,
										}}>
										<Font family='Montserrat' weight={600}>
											Introduction
										</Font>
									</Text>

									{/* <Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 5,
											marginTop: 20,
										}}>
										<Font family='Montserrat' weight={600}>
											Public Relations and Community
											Management Proposal
										</Font>
									</Text> */}
									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 5,
											flexDirection: "row",
											textAlign: "justify",
										}}>
										<Font family='Montserrat' weight={300}>
											We thank you for choosing tetrice
											consulting as your preferred
											supplier for PR management, social
											media community management, and
											content management. As a leading
											consulting firm, we specialize in
											creating comprehensive and
											successful PR and community
											management strategies that deliver
											results. Our goal is to ensure we
											add value to our clients and utilise
											our collective expertise in assiting
											Revlon to increase its brand
											awareness and engage with its target
											audience effectively
										</Font>
									</Text>

									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 5,
											marginTop: 20,
										}}>
										<Font family='Montserrat' weight={500}>
											Executive Summary
										</Font>
									</Text>
									<Text
										style={{
											fontSize: 22,
											color: APP_COLOURS.TEXTCOLOR,
											marginBottom: 5,
											flexDirection: "row",
											textAlign: "justify",
										}}>
										<Font family='Montserrat' weight={300}>
											Our plan is to design, implement and
											increase Revlon & Elizabeth Arden's
											brand awareness, build credibility
											and trust among your target audience
											as well as drive engagement across
											social media platforms. We will
											achieve these goals through a
											comprehensive strategy that includes
											media outreach, social media
											management, content creation,
											events, influencer marketing, and
											crisis management.
										</Font>
									</Text>
								</View>
							</View>
						</div>
					</View>

					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
