import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/122694-brand-identity.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page10 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = this.props.screenHeight;
		let w = this.props.screenWidth > 1000 ? 1000 : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					// paddingBottom: "10%",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					{/* <View
						style={{
							width: w,
							justifyContent: "center",
							alignItems: "center",
							position: "absolute",
							zIndex: 0,
							top: h * 0.35,
							height: "50%",
						}}>
						<InView
							as='div'
							delay={100}
							trackVisibility={true}
							onChange={(bool, entry) => {
								clearTimeout(this._timeout);
								this._timeout = setTimeout(() => {
									// console.log(bool);
									this.setState({
										inView: bool,
										showObjects: bool,
										showPhone: bool,
									});
								}, 100);
							}}>
							<div style={{}}>
								<Image
									source={logo_tetrice}
									style={{
										width: h * 0.4 * (501 / 463),
										height: h * 0.4,
										resizeMode: "contain",

										opacity: 0,
									}}
								/>
							</div>
						</InView>
					</View> */}

					<View
						style={{
							width: w,
							// height: h,
							height: "auto",
							padding: 20,
							paddingTop: "10%",
							zIndex: 1,
						}}>
						<Fade>
							<div
							// className={
							// 	this.state.inView ? `reveal` : `fadeOut`
							// }
							>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h * 0.2,
										width: w,
										marginBottom: "10%",
									}}>
									<View
										style={{
											flex: 1,
											padding: 20,
										}}>
										<Lottie
											options={defaultOptions}
											height={h * 0.4}
											width={h * 0.4}
										/>
									</View>
									<View
										style={{
											flex: 1,
											padding: 20,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 50,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													Building on your brand
												</Font>
											</Text>
										</View>
									</View>
								</View>

								{/* <View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h / 2,
										width: w,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 5,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												Revlon and Elizabeth Arden are
												two iconic beauty brands, each
												possessing a distinct identity
												and heritage. By understanding
												and embracing the essence of
												each brand, we can craft a PR
												and community management
												strategy that resonates with
												their respective target
												audiences and showcases their
												unique strengths.
											</Font>
										</Text>

										<Text
											style={{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												flexDirection: "row",
												marginVertical: 20,
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Revlon:
											</Font>
										</Text>

										{[
											{
												title: "Empowerment:",
												desc: " Revlon has always been about empowering women, encouraging self-expression, and embracing individuality through a wide range of innovative and high-quality beauty products.",
											},
											{
												title: "Accessible Beauty:",
												desc: "Revlon's mission is to provide accessible, on-trend beauty solutions, making it easy for women of all ages and backgrounds to feel confident and beautiful.",
											},
											{
												title: "Bold and Vibrant:",
												desc: "With a legacy of bold colors and innovative formulas, Revlon is synonymous with vibrancy and daring self-expression.",
											},
											{
												title: "Timeless Allure:",
												desc: "As an iconic beauty brand with a rich history, Revlon's timeless appeal continues to inspire and captivate new generations of beauty enthusiasts.",
											},
										].map((d, i) => (
											<Text
												style={{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
													marginBottom: 5,
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{d.title}
												</Font>
												<Font
													family='Montserrat'
													weight={300}>
													{d.desc}
												</Font>
											</Text>
										))}

										<Text
											style={{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												flexDirection: "row",
												marginVertical: 20,
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Elizabeth Arden:
											</Font>
										</Text>

										{[
											{
												title: "Elegance and Sophistication:",
												desc: "Elizabeth Arden embodies grace, sophistication, and a timeless appeal, catering to discerning women who value quality and refinement in their beauty products.",
											},
											{
												title: "Pioneering Skincare:",
												desc: "As a trailblazer in skincare, Elizabeth Arden is committed to innovation and scientific research, offering cutting-edge products that deliver visible results.",
											},
											{
												title: "Holistic Beauty:",
												desc: "Elizabeth Arden's philosophy goes beyond cosmetics, embracing a holistic approach to beauty that includes skincare, makeup, and fragrance, as well as a focus on inner wellbeing.",
											},
											{
												title: "Iconic Heritage:.",
												desc: "With a storied past that includes empowering women during World War II and creating the iconic Red Door Spa, Elizabeth Arden has a rich heritage that inspires trust and admiration.",
											},
										].map((d, i) => (
											<Text
												style={{
													fontSize: 19,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 15,
													flexDirection: "row",
													marginBottom: 5,
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{d.title}
												</Font>
												<Font
													family='Montserrat'
													weight={300}>
													{d.desc}
												</Font>
											</Text>
										))}

										<Text
											style={{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 5,
												flexDirection: "row",
												marginVertical: 20,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												By leveraging the unique essence
												of Revlon and Elizabeth Arden,
												our PR and community management
												strategy will reinforce the
												distinct identity of each brand,
												create emotional connections
												with their respective audiences,
												and drive brand loyalty and
												growth.
											</Font>
										</Text>
									</View>
								</View> */}
							</div>
						</Fade>
					</View>

					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
