import React, { Component } from "react";
import { View, Image, ScrollView, Linking, Text } from "react-native";
import IntroPage from "./IntroPage";
import LandingPage from "./LandingPage";
import LandingPage2 from "./LandingPage2";
import Page2 from "./Page2.js";
import Font from "react-font";
import { APP_COLOURS } from "./APP_VARS";
import Page1 from "./Page1";
import Page3 from "./Page3";
// import ScaleView from "./ScaleView";
import Page4 from "./Page4";
import Page5 from "./Page5";
import Page6 from "./Page6";
import Page7 from "./Page7";
import Page8 from "./Page8";
import Page9 from "./Page9";
import Page10 from "./Page10";
import Page11 from "./Page11";
import Page12 from "./Page12";
import Page13 from "./Page13";
import Page14 from "./Page14";
import Page15 from "./Page15";
// import { TouchableOpacity } from "react-native-web";
import TeamTetrice from "./TeamTetrice";
import Page17 from "./Page17";

if (typeof window !== "undefined") {
	require("smoothscroll-polyfill").polyfill();
}

export default class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
			showSidebar: false,
		};
	}

	componentDidMount() {
		console.log("componentDidMount");
		let accessToken = sessionStorage.getItem("accessToken");

		console.log("Approved User", accessToken);
		if (accessToken !== null) {
			// this.props.history.push("/dashboard/Home");
		} else {
			sessionStorage.clear();
			this.setState({
				loading: false,
			});
		}

		window.addEventListener("resize", this.resize.bind(this));
		this.resize();
	}

	componentDidUpdate() {}

	resize() {
		this.setState({
			screenHeight: window.innerHeight,
			screenWidth: window.innerWidth,
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize.bind(this));
	}

	render() {
		const { screenWidth } = this.state;
		// const isSmallScreen = screenWidth < 1000;
		// const scaleFactor = isSmallScreen ? 0.8 : 1.0;
		// const pageStyle = {
		// 	transform: `scale(${scaleFactor})`,
		// };

		let w = screenWidth;

		return (
			<View
				style={{
					width: this.state.screenWidth,
					height: this.state.screenHeight,
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#EDF1F4",
					overflow: "hidden",
				}}>
				<ScrollView
					ref={(ref) => {
						this.scrollRef = ref;
					}}
					overScrollMode='never'
					// pagingEnabled={true}
					// decelerationRate='fast'
					// scrollEventThrottle={160}
					// snapToInterval={this.state.screenHeight}
					contentContainerStyle={{
						alignItems: "center",
					}}>
					{/* <LandingPage {...this.state} /> */}
					<LandingPage2 {...this.state} />
					{/* <View
						style={{
							width: "100%",
							padding: 20,
							backgroundColor: "#171A20",
							// padding: "10%",
							alignItems: "flex-end",
						}}>
						<Image
							source={require("./assets/infod.png")}
							style={{
								width: 240,
								height: 120,
								resizeMode: "contain",
							}}
						/>
					</View> */}
					{/* <View
						style={{
							width: this.state.screenWidth,
							backgroundColor: "#FFF",
							borderRadius: 24,
							justifyContent: "flex-end",
							alignItems: "flex-end",
							// marginTop: 100,
							// marginBottom: 100,

							height: 800,
						}}>
						<Image
							source={require("./assets/splash_2.png")}
							style={{
								width: w * 0.66,
								height: 800,
								resizeMode: "contain",
								position: "absolute",
								right: 0,
							}}
						/>
					</View> */}

					{/* <IntroPage {...this.state} /> */}

					{/* <Page1 {...this.state} /> */}

					{/* <Page2 {...this.state} /> */}

					{/* <Page3 {...this.state} />

					<Page5 {...this.state} />

					<Page10 {...this.state} />

					<Page6 {...this.state} /> */}

					{/*

					<Page4 {...this.state} />

					<Page7 {...this.state} />

					<Page9 {...this.state} />

					<Page8 {...this.state} />

					<Page17 {...this.state} />

					<Page11 {...this.state} />

					<Page12 {...this.state} />

					<Page13 {...this.state} /> */}

					{/* <TeamTetrice /> */}
				</ScrollView>

				{/* {this.state.enquireNowForm ? (
         <EnquireNow
            {...this.props}
            {...this.state}
            nextPage={() =>
               this.setState({
                  resultsPage: true,
               })
            }
            close={() =>
               this.setState({
                  enquireNowForm: false,
               })
            }
         />
      ) : null} */}
			</View>
		);
	}
}
