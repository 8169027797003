import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/76961-weather-alert-notification.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page13 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = this.props.screenHeight;
		let w = this.props.screenWidth > 1000 ? 1000 : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",

					paddingBottom: "10%",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
						paddingTop: "10%",
					}}>
					{/* <View
						style={{
							width: w,
							justifyContent: "center",
							alignItems: "center",
							position: "absolute",
							zIndex: 0,
							top: h * 0.35,
						}}>
						<InView
							as='div'
							delay={100}
							trackVisibility={true}
							onChange={(bool, entry) => {
								clearTimeout(this._timeout);
								this._timeout = setTimeout(() => {
									// console.log(bool);
									this.setState({
										inView: bool,
										showObjects: bool,
										showPhone: bool,
									});
								}, 100);
							}}>
							<div style={{}}>
								<Image
									source={logo_tetrice}
									style={{
										width: h * 0.4 * (501 / 463),
										height: h * 0.4,
										resizeMode: "contain",

										opacity: 0,
									}}
								/>
							</div>
						</InView>
					</View> */}

					<View
						style={{
							width: w,
							// height: h,
							height: "auto",
							padding: 20,

							zIndex: 1,
						}}>
						<Fade>
							<div
							// className={
							// 	this.state.inView ? `reveal` : `fadeOut`
							// }
							>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h * 0.2,
										width: w,
										// marginBottom: "40%",
									}}>
									<View
										style={{
											flex: 1,
											// padding: 20,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 50,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													The Future of Revlon &
													Elizabeth Arden
												</Font>
											</Text>
										</View>
									</View>
									{/* <View
									style={{
										flex: 1,
										padding: 20,
									}}>
									<Lottie
										options={defaultOptions}
										height={h * 0.35}
										width={h * 0.45}
									/>
								</View> */}
								</View>

								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h / 2,
										width: w,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										<Text
											style={{
												fontSize: 17,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 25,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												Our vision for the future of
												Revlon & Elizabeth Arden is to
												reinforce their positions as
												iconic and innovative beauty
												brands, shaping the industry's
												landscape with exceptional
												products, groundbreaking
												marketing campaigns, and an
												unwavering commitment to
												customer satisfaction. By
												harnessing the power of PR,
												community management, and
												digital transformation, we aim
												to create strong connections
												with our target audience and
												drive sustainable growth for
												both brands.
											</Font>
										</Text>
										<Text
											style={{
												fontSize: 17,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 25,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												Key trends and opportunities in
												the future of luxury retail:
											</Font>
										</Text>

										{[
											{
												title: "Omnichannel retail experience:",
												desc: "Consumers are increasingly seeking seamless, personalized experiences across multiple channels. Revlon & Elizabeth Arden  will need to invest in cutting-edge digital solutions, such as virtual showrooms and augmented reality, while also elevating its in-store experience to meet these expectations.",
											},
											{
												title: "Sustainable luxury: ",
												desc: "As environmental concerns and ethical consumerism gain prominence, Revlon & Elizabeth Arden  must prioritize sustainability and transparency in its supply chain, product offerings, and overall brand values. Embracing eco-friendly materials, responsible manufacturing processes, and circular economy practices will be essential.",
											},
											{
												title: "Experiential luxury: ",
												desc: "Consumers are increasingly valuing unique, memorable experiences over material possessions. Revlon & Elizabeth Arden  can capitalize on this trend by offering one-of-a-kind events, personalized services, and exclusive access to create unforgettable moments for its customers.",
											},
											{
												title: "Digital engagement:",
												desc: "Social media and influencer marketing will continue to play a crucial role in luxury retail, with new platforms and technologies constantly emerging. Revlon & Elizabeth Arden  should stay ahead of the curve, adopting innovative content strategies and exploring emerging platforms to engage with its audience.",
											},
											{
												title: "Data-driven personalization: ",
												desc: "Advanced data analytics and artificial intelligence will enable unprecedented levels of personalization in luxury retail. By harnessing customer data, Revlon & Elizabeth Arden  can tailor product recommendations, marketing campaigns, and in-store experiences to each customer's unique preferences and behaviors.",
											},
											{
												title: "Globalization and localization:",
												desc: "As the luxury retail market expands to new regions and demographics, Revlon & Elizabeth Arden  must balance its global presence with a localized approach, catering to the specific tastes and cultural nuances of different markets.",
											},
										].map((d, i) => (
											<Text
												style={{
													fontSize: 17,
													color: APP_COLOURS.TEXTCOLOR,
													marginBottom: 5,
													flexDirection: "row",
													marginBottom: 10,
												}}>
												<Font
													family='Montserrat'
													weight={500}>
													{d.title}
												</Font>
												<Font
													family='Montserrat'
													weight={300}>
													{d.desc}
												</Font>
											</Text>
										))}

										<Text
											style={{
												fontSize: 17,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 25,
												flexDirection: "row",
												marginTop: 25,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												By embracing these trends and
												opportunities, Revlon &
												Elizabeth Arden will continue to
												thrive in the ever-evolving
												luxury retail landscape,
												creating a bright future for the
												brand and its discerning
												customers.
											</Font>
										</Text>
									</View>
								</View>
							</div>
						</Fade>
					</View>
					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
