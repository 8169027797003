import React, { Component } from "react";
// import { View, Text, ActivityIndicator, Image } from "react-native";
// import axios from "axios";
// import { api_qrscanned } from "./Api";

import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";

// import Login from "./App/Unauthed/Login";
import Main from "./App/Main";
// import Overview from "./App/Overview";
// import Scope from "./App/Scope";
// import Page15 from "./App/Page15";
// import Page14 from "./App/Page14";
// import Page16 from "./App/Page16";
// import Secondment from "./App/Secondment";
import ProposalPage from "./App/ProposalPage";

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			redirectUrl: null,
			isLoading: true,
			isMounted: false,
			analyticsData: null,
			enterCompetition: false,
		};
	}

	render() {
		return (
			<Router>
				<Routes>
					{/* <Route path='/' exact element={<Login />} /> */}
					<Route path='/' exact element={<Main />} />
					<Route
						path='/proposal/:proposalName'
						element={<ProposalPage />}
					/>
					{/* <Route path='/proposal/:proposalName' element={<Main />} />

					<Route path='/proposal/scope/' exact element={<Scope />} />

					<Route
						path='/proposal/overview/'
						exact
						element={<Overview />}
					/>

					<Route path='/ce/revloncman/' exact element={<Page14 />} />

					<Route
						path='/ce/secondment/'
						exact
						element={<Secondment />}
					/>

					<Route
						path='/ce/proposal/:proposalName'
						exact
						element={<ProposalPage />}
					/>

					<Route
						path='/ce/elizabethcman/'
						exact
						element={<Page15 />}
					/>
					<Route
						path='/ce/digitalmanagement/'
						exact
						element={<Page16 />}
					/> */}

					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</Router>
		);
	}
}

export default App;
