import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import { InView } from "react-intersection-observer";
import Fade from "react-reveal/Fade";
import Font from "react-font";
import moment from "moment";
// import logo_tetrice from "./assets/logo_tetrice.png";
import logo_tetrice from "./assets/tetrice_logo_dark.png";
// import logo_tetrice from "./assets/traqbi.svg";
import bg2 from "./assets/bg2.svg";
import { APP_COLOURS } from "./APP_VARS";
import Lottie from "react-lottie";
import * as animationData2 from "./assets/76961-weather-alert-notification.json";
import "./resizeScale.scss";
import "./hoverEffect.scss";
import PageFooter from "./PageFooter";

export default class Page11 extends Component {
	constructor(props) {
		super(props);
		this.state = { inView: false };
	}

	render() {
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData2,
			rendererSettings: {
				preserveAspectRatio: "xMidYMid slice",
			},
		};

		let h = this.props.screenHeight;
		let w = this.props.screenWidth > 1000 ? 1000 : this.props.screenWidth;

		let contain = w * 0.9 > h * 0.9 ? h * 0.89 : w * 0.9;

		let maxContain = contain > 700 ? 700 : contain;

		let ar = 636 / 545;

		return (
			<div
				style={{
					// flex: 1,
					width: w,
					// height: h,
					height: "auto",
					// backgroundColor: "#1F2229",
					// backgroundColor: "#FFF",
					// overflow: "hidden",
					justifyContent: "center",
					alignItems: "center",
					// paddingBottom: "10%",
				}}>
				<View
					style={{
						flex: 1,
						justifyContent: "center",
						alignItems: "center",
					}}>
					{/* <View
						style={{
							width: w,
							justifyContent: "center",
							alignItems: "center",
							position: "absolute",
							zIndex: 0,
							top: h * 0.35,
						}}>
						<InView
							as='div'
							delay={100}
							trackVisibility={true}
							onChange={(bool, entry) => {
								clearTimeout(this._timeout);
								this._timeout = setTimeout(() => {
									// console.log(bool);
									this.setState({
										inView: bool,
										showObjects: bool,
										showPhone: bool,
									});
								}, 100);
							}}>
							<div style={{}}>
								<Image
									source={logo_tetrice}
									style={{
										width: h * 0.4 * (501 / 463),
										height: h * 0.4,
										resizeMode: "contain",

										opacity: 0,
									}}
								/>
							</div>
						</InView>
					</View> */}

					<View
						style={{
							width: w,
							// height: h,
							height: "auto",
							padding: 20,
							paddingTop: "10%",
							zIndex: 1,
						}}>
						<Fade>
							<div
							// className={
							// 	this.state.inView ? `reveal` : `fadeOut`
							// }
							>
								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h * 0.2,
										width: w,
										marginBottom: "10%",
									}}>
									<View
										style={{
											flex: 1,
											padding: 20,
										}}>
										<Lottie
											options={defaultOptions}
											height={h * 0.35}
											width={h * 0.45}
										/>
									</View>
									<View
										style={{
											flex: 1,
											padding: 20,
											flexDirection: "row",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<View
											style={{
												flex: 1,
											}}>
											<Text
												style={{
													fontSize: 45,
													color: APP_COLOURS.TEXTCOLOR,
												}}>
												<Font
													family='Poppins'
													weight={600}>
													Protecting Our Reputation
												</Font>
											</Text>
											<Text
												style={{
													fontSize: 22,
													color: "#000",
													marginTop: 15,
													flexDirection: "row",
													opacity: 0.7,
												}}>
												<Font
													family='Montserrat'
													weight={400}>
													Prepared for the unexpected.
												</Font>
											</Text>
										</View>
									</View>
								</View>

								<View
									style={{
										// flex: 1,
										flexDirection: "row",
										// height: h / 2,
										width: w,
									}}>
									<View
										style={{
											flex: 1,
										}}>
										{/* <Text
											style={{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 5,
												flexDirection: "row",
												marginVertical: 20,
											}}>
											<Font
												family='Montserrat'
												weight={500}>
												Prepared for the unexpected.
											</Font>
										</Text> */}

										<Text
											style={{
												fontSize: 22,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												Crisis management is essential
												for maintaining the integrity
												and reputation of Revlon &
												Elizabeth Arden. In today's
												fast-paced digital landscape, a
												single negative incident can
												spread rapidly, potentially
												harming the brand image we've
												worked so hard to cultivate. By
												having a robust crisis
												management plan and a dedicated
												response team in place, we can
												effectively mitigate risks and
												minimize the impact of any
												unforeseen challenges.
											</Font>
										</Text>

										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												flexDirection: "row",
												marginTop: 20,
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												Key components of our crisis
												management plan for Revlon &
												Elizabeth Arden include:
											</Font>
										</Text>

										{[
											{
												name: "Crisis identification and monitoring",
												description:
													"Utilize social listening tools, media monitoring, and customer feedback channels to quickly identify potential crises and monitor their development.",
											},
											{
												name: "Preparedness and response protocols",
												description:
													"Establish clear guidelines and procedures for handling various types of crises, including product recalls, negative publicity, social media backlash, or natural disasters.",
											},
											{
												name: "Dedicated crisis response team",
												description:
													"Assemble a team of experts from various departments, such as PR, legal, customer service, and social media, to effectively address crises and coordinate the response strategy.",
											},
											{
												name: "Media and stakeholder communication",
												description:
													"Develop tailored communication plans for different stakeholder groups, including customers, media, influencers, and employees, ensuring timely and transparent information sharing.",
											},
											{
												name: "Post-crisis evaluation and recovery",
												description:
													"Once the crisis has been resolved, conduct a thorough assessment to identify learnings and areas for improvement. Implement necessary changes to strengthen the brand's resilience and prevent similar crises in the future.",
											},
										].map((d) => (
											<>
												<Text
													style={{
														fontSize: 19,
														color: APP_COLOURS.TEXTCOLOR,
														marginBottom: 5,
														flexDirection: "row",
													}}>
													<Font
														family='Montserrat'
														weight={500}>
														{d.name}
													</Font>
												</Text>
												<Text
													style={{
														fontSize: 19,
														color: APP_COLOURS.TEXTCOLOR,
														marginBottom: 15,
														flexDirection: "row",
													}}>
													<Font
														family='Montserrat'
														weight={300}>
														{d.description}
													</Font>
												</Text>
											</>
										))}

										<Text
											style={{
												fontSize: 19,
												color: APP_COLOURS.TEXTCOLOR,
												marginBottom: 15,
												flexDirection: "row",
											}}>
											<Font
												family='Montserrat'
												weight={300}>
												By proactively addressing
												potential threats and having a
												solid crisis management plan in
												place, Revlon & Elizabeth Arden
												can continue to uphold its
												prestigious reputation,
												weathering any storms that may
												come our way.
											</Font>
										</Text>
									</View>
								</View>
							</div>
						</Fade>
					</View>

					{/* <PageFooter /> */}
				</View>
			</div>
		);
	}
}
