import React, { Component } from "react";
import {
	View,
	Text,
	TouchableOpacity,
	TextInput,
	Dimensions,
	Image,
	ScrollView,
	ActivityIndicator,
} from "react-native";
import Font from "react-font";
import moment from "moment";
import { APP_COLOURS } from "./APP_VARS";

import "./resizeScale.scss";
import "./hoverEffect.scss";

export default class PageFooter extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					width: "100%",
					position: "absolute",
					bottom: 0,
					left: 0,
					flexDirection: "row",
					justifyContent: "space-between",
					paddingRight: 30,
					paddingLeft: 30,
					alignItems: "center",
				}}>
				<View
					style={{
						width: 200,
					}}>
					<Text
						style={{
							fontSize: 19,
							color: "#121419",
							padding: 0,
							margin: 0,
						}}>
						<Font family='Poppins' weight={800}>
							tetrice
						</Font>
					</Text>
					<View
						style={{
							flexDirection: "row",
							padding: 0,
							margin: 0,
						}}>
						<Text
							style={{
								fontSize: 10,
								color: "#121419",
								flexDirection: "row",
								fontWeight: "600",
								padding: 0,
								margin: 0,
							}}>
							Consulting
							<Text style={{ fontWeight: "300" }}>Services</Text>
						</Text>
					</View>
				</View>

				{/* <Image
					source={require("./assets/revlon_ea_logo.png")}
					style={{
						width: 100,
						height: 50,
						resizeMode: "contain",
					}}
				/> */}

				<Text
					style={{
						flex: 1,
						fontSize: 12,
						color: "#121419",
					}}>
					<Font family='Montserrat' weight={400}>
						PR and community management proposal
					</Font>
				</Text>

				<Text
					style={{
						width: 150,
						fontSize: 14,
						color: APP_COLOURS.TEXTCOLOR,
						textAlign: "right",
					}}>
					<Font family='Montserrat' weight={300}>
						info@tetrice.co.za
					</Font>
				</Text>
			</View>
		);
	}
}
