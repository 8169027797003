import React, { Component } from "react";
import { Dimensions } from "react-native";

export const APP_COLOURS = {
	PRIMARY: "",
	SECONDARY: "#748969",
	BACKGROUND: "#F2F3F6",

	TEXTCOLOR: "#020E35",
	PLACEHOLDER: "#797979",
	TEXTINPUT: "#0720D5",
	DESELECTEDCOLOR: "#EEF2FC",
	OFFWHITE: "#FBFBFC",

	WHITE: "#FFF",
	GREEN: "#07D55D",
	ORANGE: "#FFD76E",
	RED: "#FF5F5F",
	BLUE: "#0068FF",

	TRANSHALO: "rgba(0, 0, 0, 0.7)",
	BLACK: "#000",
	BG: "#1F2229",
	BG2: "#121419",
	BG3: "#1D2028",
	BG4: "#242830",
	BG5: "#4E525D",
	BG6: "#3F4659",
};

export const screenWidth = Dimensions.get("window").width;
export const screenHeight = Dimensions.get("window").height;
